@import '../styles/shared.scss';

.container {
  margin: 0 0 $SPACE_S;
}

.heading {
  display: block;
  margin: 0 0 $SPACE_XS;
}

.subheading {
  color: $COLOR_GREY_04;
  font-style: italic;
}
