@import '../styles/variables.scss';

.nav {
  align-items: baseline;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: $SPACE_XXL;

  @media screen and (min-width: 960px) {
    flex-direction: row;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: $SPACE_S 0 0 0;
    padding: 0;
  }

  li:not(:last-child) {
    margin-right: $SPACE_M;
  }
}

.logo {
  width: auto;
}

.link {
  color: $COLOR_GREY_05;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.activeLink {
  color: $COLOR_GREY_06;
  text-decoration: none;
}
