@mixin heading-base {
  font-weight: 300;
  margin: 0;
}

@mixin heading-1 {
  @include heading-base;
  font-size: 56px;
  line-height: 72px;
  margin-bottom: $BASE_UNIT * 3;
}
@mixin heading-2 {
  @include heading-base;
  font-size: 40px;
  line-height: 50px;
  margin-bottom: $BASE_UNIT * 3;
}
@mixin heading-3 {
  @include heading-base;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: $BASE_UNIT * 3;
}
@mixin heading-4 {
  @include heading-base;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: $BASE_UNIT * 2;
}
@mixin heading-5 {
  @include heading-base;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: $BASE_UNIT * 1;
}
@mixin heading-6 {
  @include heading-base;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: $BASE_UNIT * 1;
}
@mixin body {
  @include heading-base;
  font-size: 14px;
  line-height: 24px;
}
