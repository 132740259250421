$COLOR_GREY_01: #171717;
$COLOR_GREY_02: #2a2a2a;
$COLOR_GREY_03: #3d3d3d;
$COLOR_GREY_04: #8d8d8d;
$COLOR_GREY_05: #c4c4c4;
$COLOR_GREY_06: #f5f5f5;

$COLOR_PRIMARY_01: #219653;
$COLOR_PRIMARY_02: #27ae60;

$COLOR_SECONDARY_01: #2f80ed;
$COLOR_SECONDARY_02: #2d9cdb;
