@import '../styles/variables.scss';

.table {
  margin: $SPACE_S 0;
  background-color: $COLOR_GREY_01;
  border: $BORDER_DEFAULT $COLOR_GREY_03;
  border-radius: $BORDER_RADIUS;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
}

.data {
  border: 0;
  padding: $SPACE_XS $SPACE_S;
  margin: 0;
  // min-width: 100px;

  &:not(:last-child) {
    border-right: $BORDER_DEFAULT $COLOR_GREY_02;
  }
}

.dataHost,
.dataPath {
  min-width: 200px;
}

.item {
  word-break: break-word;
}

.header {
  .data {
    background-color: $COLOR_GREY_02;
    color: $COLOR_GREY_06;
    padding: $SPACE_S;
  }
}

.row {
  border: 0;
}
