@import '../styles/variables.scss';
.base {
  padding: $SPACE_S $SPACE_M;
  border-radius: $BORDER_RADIUS;
  border: 0;
  font-size: inherit;
  cursor: pointer;

  &:focus {
    outline: 1px dotted;
    outline-offset: 3px;
  }

  & ~ .base {
    margin-left: $SPACE_S;
  }

  &[disabled] {
    background-color: $COLOR_GREY_04;
  }
}

.primary {
  background-color: $COLOR_PRIMARY_01;
  color: $COLOR_GREY_06;

  &:hover {
    background-color: $COLOR_PRIMARY_02;
  }
}

.secondary {
  background-color: $COLOR_SECONDARY_01;
  color: $COLOR_GREY_06;

  &:hover {
    background-color: $COLOR_SECONDARY_02;
  }
}
