@import './shared.scss';

body {
  margin: 0;
  box-sizing: border-box;
  color: $COLOR_GREY_06;
  background-color: $COLOR_GREY_01;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 200;
  @include heading-6;
  padding: 32px;
}

* {
  box-sizing: inherit;
  transition: background-color 150ms ease-in-out;
}

input,
textarea {
  background-color: transparent;
  color: $COLOR_GREY_06;
  border-radius: $BORDER_RADIUS;
  border: 2px solid $COLOR_GREY_03;
  font-size: inherit;
  font-family: inherit;
  padding: $SPACE_S;
  margin: 0;
  width: 100%;

  &:focus {
    border: 2px solid $COLOR_GREY_04;
    outline: 0;
  }
}

code {
  display: flex;
  word-break: break-word;
}

section {
  padding: 0 0 $SPACE_XL;
  max-width: 960px;
  margin: auto;
  justify-content: center;
}

h1,
.h1 {
  @media screen and (max-width: 960px) {
    @include heading-2;
  }

  @include heading-1;
}

h2,
.h2 {
  @media screen and (max-width: 960px) {
    @include heading-3;
  }

  @include heading-2;
}

h3,
.h3 {
  @media screen and (max-width: 960px) {
    @include heading-4;
  }

  @include heading-3;
}

h4,
.h4 {
  @media screen and (max-width: 960px) {
    @include heading-5;
  }

  @include heading-4;
}

h5,
.h5 {
  @media screen and (max-width: 960px) {
    @include heading-6;
  }

  @include heading-5;
}

h6,
.h6 {
  @media screen and (max-width: 960px) {
    @include body;
  }

  @include heading-6;
}

p,
.body {
  @include body;
}

p {
  color: $COLOR_GREY_04;
  margin: 0 0 $SPACE_XS;
}
